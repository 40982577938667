.leaflet-touch .leaflet-bar a {
    width: 45px;
    height: 45px;
    line-height: 45px;
}

.leaflet-control-geosearch form input {
    min-width: 260px;
    width: 100%;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
    height: 45px;
    border-radius: 0 4px 4px 0;
    text-indent: 14px;
}

.leaflet-control-geosearch a.leaflet-bar-part:before {
    top: 28px;
    left: 25px;
    width: 10px;
    border-top: 3px solid #555;
    transform: rotateZ(45deg);
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
    top: 9px;
    left: 9px;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    border: 3px solid #555;
}

.leaflet-control-geosearch .results.active {
    padding: 8px 0;
    border-top: 1px solid #c6c6c6;
}

.leaflet-control-geosearch form {
    display: none;
    position: absolute;
    top: -2px;
    left: 42px;
    border-radius: 0 4px 4px 0;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-left: none;
    background-color: #fff;
    background-clip: padding-box;
    z-index: -1;
    height: auto;
    margin: 0;
    padding: 0 8px;
}
.leaflet-control-geosearch a.reset {
    display: none;
}
