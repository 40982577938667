body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  /*background: url("./icons/eventsip-wedadvisor-espa-c3-b1a-malala-artola.jpg")
    no-repeat center center fixed;*/
  background-color: #222d32;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*
.leaflet-marker-icon{
  content: url("./icons/imobiliarero1.png")
}
*/

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.white-back {
  background-color: white;
}

.flex-container > div {
  width: 100%;
  margin: 2%;
}

@media (min-width: 1200px) {
  .flex-container > div {
    width: 21%;
  }
}

@media (min-width: 500px) and (max-width: 1199px) {
  .flex-container > div {
    width: 46%;
  }
}

@media (min-width: 500px) {
  .flex-container > .form-group {
    width: 46%;
    margin: 0;
  }
}

.box_announce {
  background-color: white;
  border: 1px solid white;
  border-radius: 3px;
  /*padding: 15px;*/
  text-align: center;
}

@media (max-width: 499px) {
  p.price {
    height: 25.351vw;
  }
}

@media (min-width: 275px) and (max-width: 425px) {
  p.price {
    margin-bottom: -55%;
  }
}

@media (min-width: 426px) and (max-width: 499px) {
  p.price {
    margin-bottom: -52%;
  }
}

@media (min-width: 500px) and (max-width: 585px) {
  p.price {
    height: 22.351vw;
  }
}

@media (min-width: 586px) and (max-width: 615px) {
  p.price {
    height: 23.351vw;
  }
}

@media (min-width: 616px) and (max-width: 665px) {
  p.price {
    height: 24.351vw;
  }
}

@media (min-width: 666px) and (max-width: 767px) {
  p.price {
    height: 25.351vw;
  }
}

#searchBox {
  background-color: white !important;
}

@media (max-width: 499px) {
  #searchBox {
    width: 100% !important;
  }
}

.container-favorite {
  /*position: relative;*/
  top: -5%;
  right: -50%;
}

.container-favorite img {
  display: block;
}

/*
.container-favorite .fa-star {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}
*/

.container-favorite button {
  /*z-index: 1000;*/
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
}

.carousel-control {
  z-index: 20;
  width: 5%;
}
